.app {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.app .logo-container {
  height: 60px;
}
.app .logo-container .logo {
  height: 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 40px;
  background-image: url('../static/logo-flat-small.png');
  background-size: contain;
}
.app .logo-container.wide .logo {
  width: 120px;
  background-image: url('../static/logo-flat-main.png');
}
.app .logo-container.wide .logo.color {
  background-image: url('../static/logo.jpg');
}
.app .sidebar {
  color: #dcdcdc;
  background: #1D2B36;
  height: 100%;
  flex-shrink: 0;
  padding-left: 15px;
  width: 240px;
}
.app .sidebar .menu .item {
  color: #dcdcdc;
}
.app .sidebar .menu .item.active,
.app .sidebar .menu .item:hover {
  color: #c3c3c3 !important;
  background: #162029 !important;
}
.app .content-container {
  flex: 1;
}
.app .content-container .app-header {
  background-color: white;
  border: 1px solid #EEEEEE !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  height: 60px;
}
.app .content-container .app-header .inner {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.app .content-container .app-header .inner .menu-icon-container {
  justify-self: flex-start;
  flex: 1;
}
.app .content-container .app-header .inner .logo-container {
  justify-self: center;
  flex: 6;
  display: flex;
  justify-content: center;
}
.app .content-container .app-header .inner .user-container {
  flex: 1;
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
}
.app .content-container .content {
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
}
.app .content-container .content .inner {
  flex: 8;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.app .content-container .BacktoHistory {
  width: 21px;
  height: 20px;
  background-image: url('../static/leftArrowHistory.svg');
  background-size: contain;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}
.configuration {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.configuration .segment {
  flex: 1 1 auto;
}
.configuration .segment.split .content {
  display: flex;
  flex-flow: row wrap;
}
.configuration .segment.split .content .part {
  padding: 20px;
  flex: 1 1 auto;
}
.login {
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.login .outer {
  background-color: white;
  border: 1px solid #EEEEEE !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  width: 33%;
  min-width: 330px;
}
.login .outer .inner .logo {
  background-image: url('../static/logo.jpg');
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
}
.login .outer .inner .button {
  width: 100%;
  background-color: #ED5565;
  border-color: #ED5565;
  color: white;
}
.login .outer .inner .list {
  max-height: 300px;
  overflow-y: scroll;
}
.login .line-break {
  border-bottom: 1px solid #dadada;
  line-height: 0.1em;
  margin: 10px 0 20px;
  text-align: center;
}
.login .line-break .text {
  background: #fff;
  padding: 0 10px;
}
.arraylists .buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.arraylists .buttons .button {
  flex: 1 0 0;
  margin-bottom: 2px;
}
.arraylists .documentlist {
  margin-top: 10px;
}
.arraylists .filters {
  margin-top: 10px!important;
}
.document-page {
  display: flex;
}
.document-page .document-container .toolbar {
  width: 100%;
  display: flex;
}
.document-page .document-container .toolbar .filename {
  flex: 1;
}
.document-page .document-container .toolbar .menu {
  flex: 1;
  margin: 0px;
}
.document-page .document-container .toolbar .text-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.document-page .document-container .toolbar .text-container .text-inner {
  flex: 1;
  justify-items: center;
}
.document-page .document-container .react-pdf__Page__svg svg {
  width: 100%;
}
.document-page .left,
.document-page .right {
  flex: 1;
  margin: 10px;
}
.document-page .button-container {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}
.document-page .form-button {
  margin: 0px;
}
.document-page .total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.document-page .total-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 42px;
}
.document-page .total-container .total {
  font-weight: bold;
  margin-right: 10px;
}
body {
  background-color: #F3F3F3;
}
#root {
  height: 100%;
}
